import React from 'react';
import querystring from 'querystring';
import { getPage, getRedirect, getViewData } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';
import PropTypes from 'prop-types';
const isProd = process.env.NODE_ENV === 'production';

export default function CatchAllPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];

    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }

    return <Component {...componentProps} />;
}

// For SSR
export async function getServerSideProps({ req, params, res }) {
    let path = params?.path || [];
    path = path.join('/');

    const { host } = req.headers;
    let queryParams = new URL(req.url, `https://${host}`).search;

    if (queryParams.indexOf('?') === 0) {
        queryParams = queryParams.substr(1);
    }

    queryParams = querystring.parse(queryParams);

    // Try to serve page
    try {
        const {
            json: { componentName, componentProps, redirect, customResponse },
            headers,
        } = await getPage(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                'User-Agent': req.headers['user-agent'],
                host,
            },
        });
        const cookies = headers.get('set-cookie');

        if (cookies) {
            res.setHeader('Set-Cookie', cookies);
        }

        if (customResponse) {
            const { body, body64, contentType } = customResponse;
            res.setHeader('Content-Type', contentType);
            res.statusCode = 200;
            res.write(body64 ? Buffer.from(body64, 'base64') : body);
            res.end();

            return { props: {} };
        }

        if (redirect) {
            const { destination, isPermanent } = redirect;
            return {
                redirect: {
                    destination: destination,
                    permanent: isPermanent,
                },
            };
        }

        return { props: { componentName, componentProps } };
    } catch (err) {
        // When in development, show django error page on error
        if (!isProd && err.response.status >= 500) {
            const html = await err.response.text();
            return {
                props: {
                    componentName: 'PureHtmlPage',
                    componentProps: { html },
                },
            };
        }

        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Try to serve redirect
    try {
        const { json } = await getRedirect(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                'User-Agent': req.headers['user-agent'],
                host,
            },
        });
        const { destination, isPermanent } = json;
        return {
            redirect: {
                destination: destination,
                permanent: isPermanent,
            },
        };
    } catch (err) {
        if (err.response.status >= 500) {
            throw err;
        }
    }

    try {
        const {
            json: { componentName, componentProps },
        } = await getViewData(
            '404',
            {},
            {
                headers: {
                    cookie: req.headers.cookie,
                    'User-Agent': req.headers['user-agent'],
                    host,
                },
            }
        );

        res.statusCode = 404;

        return { props: { componentName, componentProps } };
    } catch (err) {
        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Serve 404 page
    return { notFound: true };
}

CatchAllPage.propTypes = {
    componentName: PropTypes.string,
    componentProps: PropTypes.object,
};

CatchAllPage.defaultProps = {
    componentName: '',
    componentProps: {},
};
