import dynamic from 'next/dynamic';

export default {
    ApartmentSchoolPage: dynamic(() => import('./ApartmentSchoolPage')),
    AreaListPage: dynamic(() => import('./AreaListPage')),
    AreaNewsListPage: dynamic(() => import('./AreaNewsListPage')),
    AreaPage: dynamic(() => import('./AreaPage')),
    ArticlePage: dynamic(() => import('./ArticlePage')),
    HomePage: dynamic(() => import('./HomePage')),
    IndexPage: dynamic(() => import('./IndexPage')),
    JobylonJobsPage: dynamic(() => import('./JobylonJobsPage')),
    NewsDetailPage: dynamic(() => import('./NewsDetailPage')),
    NewsListPage: dynamic(() => import('./NewsListPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    ObjectsPage: dynamic(() => import('./ObjectsPage')),
    ProjectPage: dynamic(() => import('./ProjectPage')),
    SearchPage: dynamic(() => import('./SearchPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    CookiePage: dynamic(() => import('./CookiePage')),
    SustainabilityNewsListPage: dynamic(() =>
        import('./SustainabilityNewsListPage')
    ),
};
